export default {
    csvToArr: (strData, strDelimiter) => {
        strDelimiter = (strDelimiter || ",");

        var objPattern = new RegExp(
            (
                "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
                "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
                "([^\"\\" + strDelimiter + "\\r\\n]*))"
            ),
            "gi"
        );

        var arrData = [[]];
        var arrMatches = null;

        while (arrMatches = objPattern.exec(strData)) {

            var strMatchedDelimiter = arrMatches[1];

            if (
                strMatchedDelimiter.length &&
                strMatchedDelimiter !== strDelimiter
            ) {
                arrData.push([]);
            }

            var strMatchedValue;
            if (arrMatches[2]) {
                strMatchedValue = arrMatches[2].replace(
                    new RegExp("\"\"", "g"),
                    "\""
                );
            } else {
                strMatchedValue = arrMatches[3];
            }
            arrData[arrData.length - 1].push(strMatchedValue);
        }

        const headers = arrData.shift()
        return arrData.map(x=>{
            let obj = {}
            let count = 0
            for (let header of headers) obj[header] = x[count++]
            return obj
        })
        // return (arrData);
    }
}