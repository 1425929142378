<template lang="pug">
  .register-teacher
    .form-container
      .form-title
        | {{ $t('addTeacher') }}
        label.uploadCSV()
          a בחר קובץ CSV להעלאה
          input(type="file", @change="uploadCSV")
      .form
        .form-group
          .input-wrapper
            label(for="firstName") {{ $t('labels.firstName') }}
            input.field.edvise-input(v-model="formData.firstName", type="text")
          .input-wrapper
            label(for="lastName") {{ $t('labels.lastName') }}
            input.field.edvise-input(v-model="formData.lastName", type="text")
        .form-group
          .input-wrapper
            label(for="email", style="direction: rtl") {{ $t('labels.email') }} (ישמש גם להתחברות דרך Google)
            input.field.edvise-input(v-model="formData.email", type="email", @blur="touched.email = true")
          .input-wrapper
            label(for="tz") {{ $t('labels.tz') }} (ישמש גם להתחברות דרך משרד החינוך)
            input.field.field.edvise-input(v-model="formData.tz", type="number", @blur="touched.tz = true")
            span.my-validation-text(:class="{ shown: !required(formData.tz) && touched.tz }") {{ $t('contactInstruction.tz') }}
        .form-group
          .input-wrapper
            label(for="semelMosad") {{ $t('labels.semelMosad') }}
            input.field.edvise-input(v-model="formData.semelMosad", type="text", :label="$t('labels.semelMosad')", @blur="touched.semelMosad = true")
            span.my-validation-text(:class="{ shown: !required(formData.semelMosad) && touched.semelMosad }") {{ $t('contactInstruction.semelMosad') }}
          .input-wrapper
            label(for="phone") {{ $t('labels.phone') }}
            input.field.edvise-input(v-model="formData.phone", type="number", :label="$t('labels.phone')", @blur="touched.phone = true")
      .top-marg.btn-container
        button.round-btn-blue.btn-form(:disabled="!isFormValid", :class="{ 'disabled-btn': !isFormValid, 'pointer': isFormValid }", @click="registerTeacher") {{ $t('send') }}
</template>

<script>
// @ is an alias to src
import adminService from '@/services/admin-service';
import csvService from '@/services/csv-service';
import dialogMixins from '@/mixins/dialog-mixins';

export default {
  name: 'RegisterTeacher',
  mixins: [dialogMixins],
  data() {
    return {
      formData: {
        tz: '',
        semelMosad: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: null
      },
      touched: {
        tz: false,
        semelMosad: false
      }
    };
  },
  computed: {
    isFormValid() {
      return (
        !!this.formData.tz &&
        !!this.formData.semelMosad &&
        this.formData.semelMosad.length === 6
      );
    }
  },
  methods: {
    legalTz(num) {
      var tot = 0;
      var tz = String(num).padStart(9,'0');
      for (let i = 0; i < 8; i++) {
        let x = (((i % 2) + 1) * tz.charAt(i));
        if (x > 9) {
          x = x.toString();
          x = parseInt(x.charAt(0)) + parseInt(x.charAt(1));
        }
        tot += x;
      }
      return (tot + parseInt(tz.charAt(8))) % 10 === 0;
    },
    required(value) {
      return !!value;
    },
    async registerTeacher() {
      let res = await adminService.registerTeacher(
        this.formData,
        this.$store.getters.getToken
      );
      if (res) {
        if (res.data.success) {
          this.dialog(await this.$t('registerTeacherSuccessful'));
          this.resetForm();
        } else {
          this.dialog(this.$t('registerTeacherFailed'));
        }
      }
    },
    resetForm() {
      this.formData = {
        tz: '',
        semelMosad: ''
      };
      this.touched = {
        tz: false,
        semelMosad: false
      };
    },
    async uploadCSV(event){
      const csvText = await event.target.files[0].text()
      const arr = csvService.csvToArr(csvText)
      let validationErrors = '', countR = 0
      let teachers = []
      for(let row of arr){
        if( Object.values(row).every(x=>!x) ){
          continue
        }
        teachers.push(row)
        if(row.semelMosad.length !== 6) validationErrors += `שורה ${String(countR + 2)}: סמל מוסד אינו באורך שש ספרות<br>`
        if(!row.firstName || !row.firstName.length) validationErrors += `שורה ${String(countR + 2)}: שם פרטי לא תקין<br>`
        if(!row.lastName || !row.lastName.length) validationErrors += `שורה ${String(countR + 2)}: שם משפחה לא תקין<br>`
        if(!row.email || !row.email.length) validationErrors += `שורה ${String(countR + 2)}: אימייל לא תקין<br>`
        if(!row.phone || !row.phone.length) validationErrors += `שורה ${String(countR + 2)}: טלפון לא תקין<br>`
        if(!row.tz || !this.legalTz(row.tz)) validationErrors += `שורה ${String(countR + 2)}: תעודת זהות אינה תקינה<br>`
        countR++
      }

      if (validationErrors.length){
        this.dialog('שגיאה באימות הנתונים',
            validationErrors,'','','800px')
      }
      else{
        let res = await adminService.registerTeachers(
            teachers,
            this.$store.getters.getToken
        );
        if (res) {
          if (res.data.success) {
            this.dialog("המורים נוצרו בהצלחה");
            this.resetForm();
          } else {
            let count = 0, errors = ''
            for(let row of res.data.status){
              if(!row.status) errors += `שורה ${count+2}: שגיאה ${row.error}<br>`
              count++
            }
            this.dialog("שגיאה ביצירה של חלק או כל המורים", errors);
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.uploadCSV{
  font-size: 1.2rem;
  float: left;
  padding-left: 50px;
  direction: rtl;
  input{
    display: none;
  }
}
.form-container {
  //   -webkit-transition: 0.3s;
  //   transition: 0.3s;
  padding-top: 2em;
  margin: 4em auto 0 auto;
  @media (max-height: 660px) {
    margin-top: 2em;
  }
  //   margin-bottom: 3em;
  min-width: 200px;
  min-height: 33em;
  //   direction: ltr;
  // -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  // background-color: white;

  .form-title {
    margin-right: 132px;
    text-align: right;
    margin-bottom: 20px;
    font-size: 40px;
    font-family: Miriam Libre, sans-serif;
    margin-top: 10px;
    font-weight: 600;
    color: #545871;
  }

  .form {
    display: flex;
    flex-direction: column;

    @media (max-height: 660px) {
      max-height: 45vh;
      overflow: auto;
    }
  }

  .form-group {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;

    label {
      font-size: 16px;
    }
  }

  .input-wrapper {
    width: 32%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    input {
      padding: 22px 10px;
      direction: rtl;
      font-size: 16px;
      width: -webkit-fill-available;
    }
  }

  .shown {
    visibility: visible;
  }

  .btn-form {
    display: inline-block;

    &.disabled {
      // background-color: #cccccc;
      cursor: auto;
    }
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 132px;
  }

  .top-marg {
    margin-top: 2em;
  }
}

.my-validation-text {
  font-size: 12px;
  color: red;
  transition: 0.3s;
  width: 100%;
  text-align: left;
  font-weight: 600;
  visibility: hidden;
}

//--------------mobile only
// @media (max-width: 768px) {
//   .form-container {
//     background-color: transparent;
//     box-shadow: none;
//     margin-top: 0;

//     .form-title {
//       font-size: 17px;
//     }

//     .form-group {
//       width: 80%;
//     }
//   }
// }

//--------------desktop only
// @media (min-width: 768px) {
.form-container {
  // max-width: 60%;

  .form-title {
    // color: #4cdef6;
    font-weight: bolder;
  }
}
// }
</style>

<style lang="scss">
.register-teacher {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  height: calc(100vh - 116px);
}
</style>
